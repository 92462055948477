import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from './Badge.module.scss';

type Props = {
    children: ReactNode;
    className?: string;
};

export function Badge({ children, className }: Props) {
    return <span className={classNames(styles.badge, className)}>{children}</span>;
}
