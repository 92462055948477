import type { TranslatedCategory } from '@prezly/sdk';

import { Link } from '@/components/Link';
import { getCategoryName } from '@/custom';

import { Badge } from '../Badge';

type Props = {
    category: TranslatedCategory;
    className?: string;
    headless?: boolean;
};

export function CategoryLink({ category, className, headless = false }: Props) {
    const categoryName = getCategoryName(category.name);

    return (
        <Link
            href={{
                routeName: 'category',
                params: { slug: category.slug, localeCode: category.locale },
            }}
            className={className}
        >
            {headless ? <>{categoryName}</> : <Badge>{categoryName}</Badge>}
        </Link>
    );
}
