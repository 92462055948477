'use client';

import type { UploadcareImage } from '@prezly/uploadcare';
import UploadcareImageLoader from '@uploadcare/nextjs-loader';
import classNames from 'classnames';
import type { SVGProps } from 'react';

import type { ListStory } from '@/types';
import { getUploadcareImage } from '@/utils';

import { getCardImageSizes, getStoryThumbnail, type ImageSize } from './lib';

import styles from './StoryImage.module.scss';

type Props = {
    className?: string;
    forceAspectRatio?: number;
    isStatic?: boolean;
    placeholderClassName?: string;
    size: ImageSize;
    thumbnailImage: ListStory['thumbnail_image'];
    title: string;
};

export function StoryImage({
    className,
    forceAspectRatio,
    isStatic = false,
    placeholderClassName,
    size,
    thumbnailImage,
    title,
}: Props) {
    const image = getStoryThumbnail(thumbnailImage);
    const uploadcareImage = applyAspectRatio(getUploadcareImage(image), forceAspectRatio);

    if (uploadcareImage) {
        return (
            <div className={classNames(styles.imageContainer, className)}>
                <UploadcareImageLoader
                    fill
                    alt={title}
                    className={classNames(styles.image, {
                        [styles.static]: isStatic,
                    })}
                    src={uploadcareImage.cdnUrl}
                    sizes={getCardImageSizes(size)}
                />
            </div>
        );
    }

    return (
        <span
            className={classNames(styles.placeholder, placeholderClassName, {
                [styles.static]: isStatic,
            })}
        >
            <LogoSvg width={150} />
        </span>
    );
}

function applyAspectRatio(
    image: UploadcareImage | null,
    aspectRatio: number | undefined,
): UploadcareImage | null {
    if (!image || !aspectRatio) {
        return image;
    }

    const actualAspectRatio = image.width / image.height;

    if (actualAspectRatio > aspectRatio) {
        const [width, height] = constrain(Math.round(image.height * aspectRatio), image.height);
        // The image is wider than it should
        return image.scaleCrop(width, height, true);
    }

    if (actualAspectRatio < aspectRatio) {
        // The image is taller than it should
        const [width, height] = constrain(image.width, Math.round(image.width / aspectRatio));
        return image.scaleCrop(width, height, true);
    }

    return image;
}

const MAX_SCALED_SIZE = 3000;

/**
 * Scale down vectors, which has at least one of dimensions > 3000px.
 * This is necessary because Uploadcare scale_crop transformation fails if one of the dimensions is larger than 3000px.
 */
function constrain(width: number, height: number): [number, number] {
    if (width < MAX_SCALED_SIZE && height < MAX_SCALED_SIZE) {
        return [width, height];
    }
    return [
        Math.min(MAX_SCALED_SIZE, Math.round((width / height) * MAX_SCALED_SIZE)),
        Math.min(MAX_SCALED_SIZE, Math.round((height / width) * MAX_SCALED_SIZE)),
    ];
}

function LogoSvg(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 46" fill="none" {...props}>
            <g clipPath="url(#logo-fallback)">
                <path
                    fill="#036"
                    d="M43.25.612s1.96 11.75 3.75 22.07c-3.95.59-7.6 1.52-10.94 2.64l-1.74-12.93s-1.72.89-3.15 3.27c-.67 1.12-5.6 8.89-11.05 17.61-4.95 3.37-7.59 6.19-7.59 6.19l4.36-.87-3.87 6.21H0l21.45-32.9S28.54.792 39.86.792l3.39-.19v.01Zm.96 44.14c-4.62 0-6.3-6.61-6.79-9.24l-.15-1.13c3.71-.66 7.51-1.09 11.52-1.5l.28 1.5c3.22 8.68 8.76 10.26 8.73 10.36H44.21v.01Zm8.91-24.87c4.9-13.2 22.7-23.88 48.88-18.42l-2.09 9.18c-17.89-3.28-29.08 1.86-33.78 8.36-4.83.38-9.04.55-13.01.88Zm23.15 25.59c-18.36 0-23.32-8.04-24.33-14.67 3.91-.45 7.91-1 12.05-1.79 1.62 5.24 8.08 8.78 19.24 5.98l2.73-10.46c1.48-.65 2.97-1.35 4.45-2.1 2.55-1.2 5.27-3.01 7.98-4.56l-5.69 24.73c-5.58 1.72-10.17 2.87-16.44 2.87"
                />
                <path
                    fill="#9C0"
                    d="M46.99 22.682c14.24-2.24 30.64-1.25 51.7-7.47-35.41 22.53-61.01 10.99-86.17 24.24 0 0 12.73-13.54 34.47-16.78"
                />
            </g>
            <defs>
                <clipPath id="logo-a">
                    <path fill="#fff" d="M0 .002h102v45.47H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
