import type { SVGProps } from 'react';

export function ChevronDownIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 5" fill="none" {...props}>
            <mask
                id="chevron-down-icon-mask"
                width={8}
                height={5}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M7.996 4.905H0V.095h7.996v4.81Z"
                    clipRule="evenodd"
                />
            </mask>
            <g mask="url(#chevron-down-icon-mask)">
                <path
                    fill="#1F2426"
                    fillRule="evenodd"
                    d="m4.39 4.73 3.444-3.628a.616.616 0 0 0 0-.833.538.538 0 0 0-.793 0L3.998 3.472.955.269a.538.538 0 0 0-.793 0 .616.616 0 0 0 0 .833L3.59 4.729a.542.542 0 0 0 .801 0Z"
                    clipRule="evenodd"
                />
            </g>
        </svg>
    );
}
