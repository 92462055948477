import type { SVGProps } from 'react';

export function LinkedinIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none" {...props}>
            <rect width={30} height={30} fill="#006FA6" rx={3} />
            <g fill="#fff">
                <path
                    fillRule="evenodd"
                    d="M18.094 12.732c-1.174 0-1.965.66-2.29 1.296h-.035v-1.092h-2.325v8.016h2.418v-3.96c0-1.044.197-2.052 1.453-2.052 1.244 0 1.255 1.2 1.255 2.124v3.9H21v-4.416c0-2.16-.453-3.816-2.906-3.816Z"
                    clipRule="evenodd"
                />
                <path d="M9.573 12.984h2.418V21H9.573z" />
                <path
                    fillRule="evenodd"
                    d="M10.782 9c-.78 0-1.407.648-1.407 1.44 0 .792.628 1.44 1.407 1.44.778 0 1.406-.648 1.406-1.44-.011-.792-.64-1.44-1.406-1.44Z"
                    clipRule="evenodd"
                />
            </g>
        </svg>
    );
}
