import type { SVGProps } from 'react';

export function FacebookIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none" {...props}>
            <rect width={30} height={30} fill="#4E69A2" rx={3} />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M16.125 12.984v-.996s-.094-.948.45-.948h1.106V9h-1.884s-1.585-.012-1.585 1.92v2.064h-1.087v1.644h1.088c-.01 2.616-.01 6.372-.01 6.372h1.922v-6.372H17.4l.413-1.644h-1.688Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
