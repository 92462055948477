import type { SVGProps } from 'react';

export function YoutubeIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none" {...props}>
            <rect width={30} height={30} fill="#F6001D" rx={3} />
            <path fill="#fff" fillRule="evenodd" d="m20 15-8 5V10l8 5Z" clipRule="evenodd" />
        </svg>
    );
}
