import type { ThemeSettings } from '@/theme-settings';

export const AG_CUSTOM_THEME_SETTINGS = {
    accent_color: '#99CC00',
    palette: {
        green: {
            light: '#E2EED9',
            lighter: '#EDF6CB',
            accent: '#99CC00',
        },
        slate: {
            light: '#A1A9AF',
            medium: '#616F74',
            dark: '#4B5559',
            darker: '#1F2426',
        },
        blue: {
            light: '#E9EDF6',
            lighter: '#CCEDFF',
            accent: '#007DB8',
        },
    },
} satisfies Partial<ThemeSettings>;
