import type { SVGProps } from 'react';

export function TwitterIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="none" {...props}>
            <rect width={30} height={30} fill="#000" rx={3} />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="m19.55 9-3.879 4.509L12.573 9H9l4.684 6.819L9 21.264h1.059l4.096-4.761 3.272 4.761H21l-4.858-7.071L20.608 9H19.55Zm-4.859 6.879-.475-.68-3.776-5.402h1.626l3.048 4.36.474.679 3.963 5.667h-1.627l-3.232-4.624Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
