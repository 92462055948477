import type { SVGProps } from 'react';

export function CalendarIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={22} height={25} fill="none" {...props}>
            <path
                fill="#A1A9AF"
                d="M18.7 2.6h-2.2V1.5c0-.66-.44-1.1-1.1-1.1-.66 0-1.1.44-1.1 1.1v1.1H7.7V1.5C7.7.84 7.26.4 6.6.4c-.66 0-1.1.44-1.1 1.1v1.1H3.3C1.43 2.6 0 4.03 0 5.9v15.4c0 1.87 1.43 3.3 3.3 3.3h15.4c1.87 0 3.3-1.43 3.3-3.3V5.9c0-1.87-1.43-3.3-3.3-3.3ZM3.3 4.8h2.2v1.1c0 .66.44 1.1 1.1 1.1.66 0 1.1-.44 1.1-1.1V4.8h6.6v1.1c0 .66.44 1.1 1.1 1.1.66 0 1.1-.44 1.1-1.1V4.8h2.2c.66 0 1.1.44 1.1 1.1v3.3H2.2V5.9c0-.66.44-1.1 1.1-1.1Zm15.4 17.6H3.3c-.66 0-1.1-.44-1.1-1.1v-9.9h17.6v9.9c0 .66-.44 1.1-1.1 1.1Z"
            />
        </svg>
    );
}
