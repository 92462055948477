import type { TranslatedCategory } from '@prezly/sdk';
import classNames from 'classnames';
import type { ReactNode } from 'react';

import { FormattedDate } from '@/adapters/client';
import { Link } from '@/components/Link';
import { CalendarIcon } from '@/custom/icons';
import type { ListStory } from '@/types';

import { Card } from '../Card';
import { CategoriesList } from '../CategoriesList';
import { StoryImage } from '../StoryImage';

import styles from './StoryCard.module.scss';

type Props = {
    className?: string;
    layout: 'horizontal' | 'vertical';
    publishedAt: string | null;
    slug: string;
    thumbnailImage?: ListStory['thumbnail_image'];
    title: ReactNode;
    titleAsString: string;
    translatedCategories: TranslatedCategory[];
    withStaticImage?: boolean;
    withFallbackImage?: boolean;
};

export function StoryCard({
    className,
    layout,
    publishedAt,
    slug,
    thumbnailImage,
    title,
    titleAsString,
    translatedCategories,
    withStaticImage = false,
    withFallbackImage = false,
}: Props) {
    const hasCategories = translatedCategories.length > 0;

    return (
        <Card
            className={classNames(styles.container, className, styles.small, {
                [styles.horizontal]: layout === 'horizontal',
                [styles.vertical]: layout === 'vertical',
                [styles.withStaticImage]: withStaticImage,
            })}
        >
            {thumbnailImage && (
                <Link
                    href={{ routeName: 'story', params: { slug } }}
                    className={styles.imageWrapper}
                    title={titleAsString}
                >
                    <StoryImage
                        className={styles.image}
                        forceAspectRatio={7 / 4}
                        isStatic={withStaticImage}
                        placeholderClassName={styles.placeholder}
                        size="medium"
                        thumbnailImage={thumbnailImage}
                        title={titleAsString}
                    />
                </Link>
            )}
            {!thumbnailImage && withFallbackImage && (
                <Link
                    href={{ routeName: 'story', params: { slug } }}
                    className={styles.imageWrapper}
                    title={titleAsString}
                >
                    <StoryImage
                        className={styles.image}
                        forceAspectRatio={7 / 4}
                        isStatic={withStaticImage}
                        placeholderClassName={styles.placeholder}
                        thumbnailImage={null}
                        size="medium"
                        title={titleAsString}
                    />
                </Link>
            )}

            <div className={styles.content}>
                <h2 className={styles.title}>
                    <Link
                        href={{ routeName: 'story', params: { slug } }}
                        className={styles.titleLink}
                    >
                        {title}
                    </Link>
                </h2>

                <div className={styles.meta}>
                    {publishedAt && (
                        <div className={styles.date}>
                            <CalendarIcon />
                            <FormattedDate value={publishedAt} />
                        </div>
                    )}
                    {hasCategories && publishedAt && <span className={styles.separator} />}
                    {hasCategories && (
                        <CategoriesList
                            className={styles.categories}
                            categories={translatedCategories}
                            isStatic
                            showAllCategories
                        />
                    )}
                </div>
            </div>
        </Card>
    );
}
