import classNames from 'classnames';
import type { ComponentPropsWithRef, ForwardedRef } from 'react';
import { forwardRef } from 'react';

import styles from './Card.module.scss';

type Props = ComponentPropsWithRef<'div'>;

export const Card = forwardRef<HTMLDivElement, Props>(
    ({ className, children, ...props }, ref: ForwardedRef<HTMLDivElement>) => {
        return (
            <div ref={ref} className={classNames(styles.card, className)} {...props}>
                {children}
            </div>
        );
    },
);

Card.displayName = 'Card';
